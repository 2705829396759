import React from 'react';
import './../styles.css'; 

const About = () => {
  return (
    <div className="about-section">
      <h4>About Us</h4>
      <p>
        ...... 
      </p>
    </div>
  );
};

export default About;
